<template>
  <div class="user container">
    <div class="user__avatar">
      <div>
        <div class="user__avatar__circle">
          <van-icon name="manager" />
        </div>
        <div class="user__avatar__info">
          <p class="user__info__aliasEN" style="color: #fff; font-size: 14px">{{ userInfo.userName }}</p>
          <p class="user__info__aliasCN" style="color: #fff; font-size: 14px">{{ userInfo.name }}</p>
        </div>
      </div>
    </div>
    <div class="user__supplement">
      <div class="user__supplement__about-us" @click="updateVersions">
        <span>当前版本</span>
        <span>
          {{ currentVersions }}
          <!-- <van-icon name="arrow" color="#777" /> -->
        </span>
      </div>
      <div class="user__supplement__logout">
        <van-button round type="primary" @click="logOut" class="logoutBtn">退出登录</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Dialog } from 'vant'
import JsBridge from '@/utils/jsBridge'
import { NATIVE_TYPE } from '@/utils/types'
import { useStore } from 'vuex'
import { decodeLocalStr } from '@/utils/common'

export default {
  name: 'User',
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  setup() {
    const userInfo = ref({})
    const router = useRouter()
    const store = useStore()

    onMounted(() => {
      const user = decodeLocalStr('userInfo')
      userInfo.value = user
      JsBridge.jsCallNative(NATIVE_TYPE.VERSIONS, {})
    })

    //安卓检查版本更新
    const updateVersions = () => {
      const userAgent = navigator.userAgent
      if (userAgent.indexOf('_Web') != -1) {
        let isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1 //安卓
        if (isAndroid) {
          JsBridge.jsCallNative(NATIVE_TYPE.UPDATE_VERSIONS, {})
        }
      }
    }

    const logOut = () => {
      Dialog.confirm({
        message: '是否退出登录？',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          localStorage.removeItem('isLogin')
          localStorage.removeItem('WEBSIDE_SID')
          // 移除权限
          // localStorage.removeItem('auth_desktop:baseData')
          // localStorage.removeItem('auth_desktop:inventory')
          // 通知 app 登出
          JsBridge.jsCallNative(NATIVE_TYPE.LOG_OUT, {})
          // 清空权限
          store.commit('permission/removePermission')
          router.replace({
            name: 'Login',
            query: {}
          })
        })
        .catch(() => {})
    }
    return {
      logOut,
      userInfo,
      currentVersions: computed(() => store.state.system.currentVersions),
      updateVersions
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.user {
  width: 100%;
  &__avatar {
    height: 300px;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    box-sizing: border-box;
    color: $font-color;
    &__circle {
      font-size: 32px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      width: 60px;
      border: 1px solid $background-color;
      border-radius: 50%;
      background-color: #cec8c8;
      color: $background-color;
    }
    &__info {
      p {
        text-align: center;
      }
    }
  }
  &__supplement {
    div {
      margin-top: 16px;
      height: 32px;
      line-height: 32px;
      background-color: #fff;
    }
    &__about-us {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
    }
    &__logout {
      text-align: center;
      color: $blue;
      .logoutBtn {
        width: 200px;
        height: 36px;
        line-height: 36px;
      }
    }
  }
}
</style>
